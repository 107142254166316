import React from "react"
import { MediaContextProvider } from "./src/utils/media"
import { AllResultsProvider } from "./src/utils/context/allResults-context"
import { SearchResultsProvider } from "./src/utils/context/searchResults-context"
import { FilterValuesProvider } from "./src/utils/context/filterValues-context"
import { AlertProvider } from "./src/utils/context/alert-context"
import { HasPopularFiltersProvider } from "./src/utils/context/hasPopularFilters-context"
import { HasQueryProvider } from "./src/utils/context/hasQuery-context"
import { IsFilterOpenProvider } from "./src/utils/context/isFilterOpen-context"
import { IsSheetOpenProvider } from "./src/utils/context/isSheetOpen-context"
import { ResetFiltersProvider } from "./src/utils/context/resetFilters-context"
import { FuseSearchListProvider } from "./src/utils/context/fuseSearchList-context"
import { FuseFilterListProvider } from "./src/utils/context/fuseFilterList-context"
import { SearchQueryProvider } from "./src/utils/context/searchQuery-context"
import { VisibleMarkersProvider } from "./src/utils/context/visibleMarkers-context"
import { HasQueryFromIndexProvider } from "./src/utils/context/hasQueryFromIndex-context"
import { NavProvider } from "./src/utils/context/nav-context"

export const wrapRootElement = ({ element }) => {
  return (
    <SearchQueryProvider>
      <ResetFiltersProvider>
        <HasQueryProvider>
          <HasPopularFiltersProvider>
            <HasQueryFromIndexProvider>
              <AllResultsProvider>
                <SearchResultsProvider>
                  <FilterValuesProvider>
                    <AlertProvider>
                      <MediaContextProvider>
                        <FuseSearchListProvider>
                          <FuseFilterListProvider>
                            <IsSheetOpenProvider>
                              <IsFilterOpenProvider>
                                <VisibleMarkersProvider>
                                  <NavProvider>{element}</NavProvider>
                                </VisibleMarkersProvider>
                              </IsFilterOpenProvider>
                            </IsSheetOpenProvider>
                          </FuseFilterListProvider>
                        </FuseSearchListProvider>
                      </MediaContextProvider>
                    </AlertProvider>
                  </FilterValuesProvider>
                </SearchResultsProvider>
              </AllResultsProvider>
            </HasQueryFromIndexProvider>
          </HasPopularFiltersProvider>
        </HasQueryProvider>
      </ResetFiltersProvider>
    </SearchQueryProvider>
  )
}
