import React, { useState, createContext } from "react"
import { useClearedFilters } from "../hooks/use-cleared-filters"

export const FilterValuesContext = createContext()

export const FilterValuesProvider = ({ children }) => {
  const initialFilters = useClearedFilters()
  const [filterValues, setFilterValues] = useState(initialFilters)

  return (
    <FilterValuesContext.Provider value={[filterValues, setFilterValues]}>
      {children}
    </FilterValuesContext.Provider>
  )
}
