import React, { useState, createContext } from "react"

export const VisibleMarkersContext = createContext()

export const VisibleMarkersProvider = ({ children }) => {
  const [visibleMarkers, setVisibleMarkers] = useState([])

  return (
    <VisibleMarkersContext.Provider value={[visibleMarkers, setVisibleMarkers]}>
      {children}
    </VisibleMarkersContext.Provider>
  )
}
