import React, { useState, createContext } from "react"
import { useServices } from "../hooks/use-services"

export const SearchResultsContext = createContext()

export const SearchResultsProvider = ({ children }) => {
  const { allServices } = useServices()
  const [searchResults, setSearchResults] = useState(allServices)

  return (
    <SearchResultsContext.Provider value={[searchResults, setSearchResults]}>
      {children}
    </SearchResultsContext.Provider>
  )
}
