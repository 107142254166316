import React, { useState, createContext } from "react"

export const IsFilterOpenContext = createContext()

export const IsFilterOpenProvider = ({ children }) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false)

  return (
    <IsFilterOpenContext.Provider value={[isFilterOpen, setIsFilterOpen]}>
      {children}
    </IsFilterOpenContext.Provider>
  )
}
