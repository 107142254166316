import React, { useState, createContext } from "react"

export const HasQueryFromIndexContext = createContext()

export const HasQueryFromIndexProvider = ({ children }) => {
  const [hasQueryFromIndex, setHasQueryFromIndex] = useState(false)

  return (
    <HasQueryFromIndexContext.Provider
      value={[hasQueryFromIndex, setHasQueryFromIndex]}
    >
      {children}
    </HasQueryFromIndexContext.Provider>
  )
}
