import React, { useState, useEffect, createContext, useContext } from "react"
import { SearchResultsContext } from "./searchResults-context"
import Fuse from "fuse.js"

export const FuseFilterListContext = createContext()

export const FuseFilterListProvider = ({ children }) => {
  const [searchResults, setSearchResults] = useContext(SearchResultsContext) //eslint-disable-line

  const [fuseFilterList, setFuseFilterList] = useState(null)

  // Build fuse search index
  useEffect(() => {
    //Set the options for fuse
    const fuseOptionsFilter = {
      threshold: "0.2",
      useExtendedSearch: true,
      keys: [
        "properties.categories.slug",
        "properties.features.slug",
        "properties.costs.slug",
        "properties.languages.slug",
        "properties.formats.slug",
      ],
    }
    // Create the new fuse search object, searches all services
    const fuse = new Fuse(searchResults, fuseOptionsFilter)
    setFuseFilterList(fuse)
  }, [searchResults]) //eslint-disable-line

  return (
    <FuseFilterListContext.Provider value={[fuseFilterList, setFuseFilterList]}>
      {children}
    </FuseFilterListContext.Provider>
  )
}
