import { useStaticQuery, graphql } from "gatsby"
export const useClearedFilters = () => {
  // Get the category and language data from SANITY.io
  const data = useStaticQuery(graphql`
    {
      allSanityServiceCategory {
        nodes {
          id
          _id
          name
          slug {
            current
          }
        }
      }
      allSanityServiceFeature {
        nodes {
          id
          _id
          name
          slug {
            current
          }
        }
      }
      allSanityServiceFormat {
        nodes {
          id
          _id
          name
          slug {
            current
          }
        }
      }
      allSanityServiceLanguage {
        nodes {
          id
          _id
          name
          slug {
            current
          }
        }
      }
      allSanityServiceCost {
        nodes {
          id
          _id
          name
          slug {
            current
          }
        }
      }
    }
  `)

  // Pull out just arrays of the data which can be
  const serviceCategories = data.allSanityServiceCategory.nodes
  const serviceFeatures = data.allSanityServiceFeature.nodes
  const serviceFormats = data.allSanityServiceFormat.nodes
  const serviceLanguages = data.allSanityServiceLanguage.nodes
  const serviceCosts = data.allSanityServiceCost.nodes

  // Function to handle creating the cleared form format
  const createClearedObject = (categories) => {
    const keyValuePair = categories.map((category) => {
      const slug = category.slug.current
      return {
        [slug]: false,
      }
    })
    const asObject = Object.assign({}, ...keyValuePair)
    return asObject
  }
  // Deal with cleared categories
  const clearedCategories = createClearedObject(serviceCategories)

  // Deal with cleared features
  const clearedFeatures = createClearedObject(serviceFeatures)

  // Deal with cleared formats
  const clearedFormats = createClearedObject(serviceFormats)

  // Deal with cleared languages
  const clearedLanguages = createClearedObject(serviceLanguages)

  // Deal with cleared costs
  const clearedCosts = createClearedObject(serviceCosts)

  // Final cleared form
  const clearedFilters = {
    categories: clearedCategories,
    features: clearedFeatures,
    formats: clearedFormats,
    costs: clearedCosts,
    languages: clearedLanguages,
  }

  return clearedFilters
}
