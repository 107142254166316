import { createMedia } from "@artsy/fresnel"

const mediaQueries = createMedia({
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1280,
  },
})

// Make styles for injection into the header of the page
export const mediaStyles = mediaQueries.createMediaStyle()

export const { Media, MediaContextProvider } = mediaQueries
