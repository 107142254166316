import React, { useState, useEffect, createContext } from "react"
import { useServices } from "../hooks/use-services"
import Fuse from "fuse.js"

export const FuseSearchListContext = createContext()

export const FuseSearchListProvider = ({ children }) => {
  const [fuseSearchList, setFuseSearchList] = useState(null)
  const { allServices } = useServices()

  // Build fuse search index
  useEffect(() => {
    //Set the options for fuse
    const fuseOptionsSearch = {
      threshold: "0.3",
      findAllMatches: true,
      ignoreLocation: true,
      includeScore: true,
      keys: [
        "properties.name",
        "properties.summary",
        "properties.categories.name",
        "properties.features.name",
        "properties.formats.name",
        "properties.costs.name",
        "properties.categories.slug",
        "properties.features.slug",
        "properties.costs.slug",
        "properties.languages.slug",
        "properties.formats.slug",
        "properties.languages.name",
        "properties.address.city",
      ],
    }
    // Create the new fuse search object, searches all services
    const fuse = new Fuse(allServices, fuseOptionsSearch)
    setFuseSearchList(fuse)
  }, []) //eslint-disable-line

  return (
    <FuseSearchListContext.Provider value={[fuseSearchList, setFuseSearchList]}>
      {children}
    </FuseSearchListContext.Provider>
  )
}
