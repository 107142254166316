import React, { useState, createContext } from "react"

export const IsSheetOpenContext = createContext()

export const IsSheetOpenProvider = ({ children }) => {
  const [isSheetOpen, setIsSheetOpen] = useState(false)

  return (
    <IsSheetOpenContext.Provider value={[isSheetOpen, setIsSheetOpen]}>
      {children}
    </IsSheetOpenContext.Provider>
  )
}
