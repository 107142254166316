import React, { useState, createContext } from "react"

export const SearchQueryContext = createContext()

export const SearchQueryProvider = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState({ searchQuery: "" })

  return (
    <SearchQueryContext.Provider value={[searchQuery, setSearchQuery]}>
      {children}
    </SearchQueryContext.Provider>
  )
}
