import React, { useState, createContext } from "react"

export const ResetFiltersContext = createContext()

export const ResetFiltersProvider = ({ children }) => {
  const [resetFilters, setResetFilters] = useState(false)

  return (
    <ResetFiltersContext.Provider value={[resetFilters, setResetFilters]}>
      {children}
    </ResetFiltersContext.Provider>
  )
}
