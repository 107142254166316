import React, { useState, createContext } from "react"
import { useServices } from "../hooks/use-services"

export const AllResultsContext = createContext()

export const AllResultsProvider = ({ children }) => {
  const { allServices } = useServices()
  const [allResults, setAllResults] = useState(allServices)

  return (
    <AllResultsContext.Provider value={[allResults, setAllResults]}>
      {children}
    </AllResultsContext.Provider>
  )
}
