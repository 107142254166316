// See https://theme-ui.com/ for more info and also https://www.gatsbyjs.com/docs/theme-ui/
// Try changing some of the colors below to see what happens.
import { baseColors } from "./baseColors"
import { darken, lighten } from "@theme-ui/color"
import "@fontsource/poppins/400.css"
import "@fontsource/poppins/400-italic.css"
import "@fontsource/poppins/600.css"
import "@fontsource/poppins/600-italic.css"

const theme = {
  config: {
    useColorSchemeMediaQuery: false,
  },
  breakpoints: ["480px", "768px", "1280px", "1440px"],
  fonts: {
    body: '"Poppins", system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    heading: "inherit",
    monospace:
      'Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace',
  },
  fontSizes: [
    "0.875rem",
    "1rem",
    "1.25rem",
    "1.5rem",
    "1.875rem",
    "2.25rem",
    "3rem",
    "4rem",
    "4.5rem",
  ],
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 600,
    extrabold: 800,
    black: 900,
    body: 400,
    heading: 600,
  },
  letterSpacings: {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0",
    wide: "0.025em",
    wider: "0.05em",
    widest: "0.1em",
  },
  lineHeights: {
    none: "1",
    tight: "1.25",
    snug: "1.375",
    normal: "1.5",
    relaxed: "1.625",
    loose: "2",
    body: "1.8",
    heading: "1.25",
  },
  colors: {
    ...baseColors,
    background: "#f7fafc",
    text: "#2d3748",
    textGray: "#6a6a6a", // Dark grey
    primary: "#4c7f37", // Dark green Dont forget to change this in the map's css, and in rough notation, manifest
    secondary: "#87C660", // Lighter green
    accent: "#402352", // Deep purple
    highlight: "#844B9D", // Lighter purple
    link: "#4c7f37", // Same as primary
    muted: "#E6E6E6", // very light grey
    mutedGreen: "#e1efdb", // very light green
    mutedPurple: "#d8c2e5", // very light purple
    header: {
      background: "#f7fafc",
      backgroundOpen: "#ebe8fc",
      text: "#402352",
      textOpen: "#402352",
      icons: "#2d3748",
      iconsOpen: "#2d3748",
    },
  },
  sizes: {
    maxPageWidth: "none", // Sets the max width of elements like the header/footer large screens
    maxContentWidth: "720px", // Sets the container size on larger screens, e.g. tablets and laptops
    iconsFooter: "32px", // Sets the icons size for the footer
    iconsHeader: "20px", // Sets the icons size for the header
  },
  space: [
    "0",
    "0.25rem",
    "0.5rem",
    "1rem",
    "2rem",
    "4rem",
    "8rem",
    "16rem",
    "32rem",
  ],
  shadows: {
    xs: "0 0 0 1px rgba(0, 0, 0, 0.05)",
    sm: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
    default: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    md: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    lg: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
    xl: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
    "2xl": "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
    inner: "inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)",
    outline: "0 0 0 3px rgba(66, 153, 225, 0.5)",
    none: "none",
  },
  styles: {
    root: {
      backgroundColor: "background",
      color: "text",
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
    },
    p: {
      fontSize: 2,
    },
    a: {
      color: "link",
      transition: "all 0.2s",
      //Handle overflowing text
      overflowWrap: "break-word",
      wordWrap: "break-word",
      wordBreak: "break-word",
      ":hover": {
        color: darken("link", 0.15),
      },
    },
    ul: {
      fontSize: 2,
      pl: [4, 5, null, null, null],
    },
    ol: {
      fontSize: 2,
      pl: [4, 5, null, null, null],
    },
    li: {
      marginBottom: "20px",
      lineHeight: "snug",
    },
    h1: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      m: 0,
      mb: 1,
      fontSize: 6,
      mt: 4,
    },
    h2: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      m: 0,
      mb: 1,
      fontSize: 5,
      mt: 4,
    },
    h3: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      m: 0,
      mb: 1,
      fontSize: 4,
      mt: 3,
    },
    h4: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      m: 0,
      mb: 1,
      fontSize: 3,
    },
    h5: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      m: 0,
      mb: 1,
      fontSize: 2,
    },
    h6: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      m: 0,
      mb: 2,
      fontSize: 1,
    },
    blockquote: {
      bg: "muted",
      p: 3,
      my: 3,
      mx: [1, 2, 4, null, null],
      borderLeft: "5px solid",
      borderColor: "primary",
      borderRadius: "6px",
    },
    table: {
      width: "100%",
      my: 3,
      borderCollapse: "collapse",
    },
    th: {
      verticalAlign: "bottom",
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: "muted",
      backgroundColor: "muted",
      padding: 2,
      textAlign: "inherit",
    },
    td: {
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: "muted",
      verticalAlign: "top",
      padding: 2,
    },
  },
  buttons: {
    primary: {
      cursor: "pointer",
      color: "white",
      bg: "primary",
      px: 2,
      py: 1,
      borderColor: "primary",
      borderWidth: "2px",
      borderRadius: "6px",
      borderStyle: "solid",
      boxShadow: "default",
      textDecoration: "none",
      transition: "all .3s ease-in-out",
      ":hover, :active, :focus": {
        bg: darken("primary", 0.15),
        borderColor: darken("primary", 0.15),
      },
    },
    secondary: {
      cursor: "pointer",
      color: "primary",
      bg: "transparent",
      borderColor: "primary",
      borderWidth: "2px",
      borderRadius: "6px",
      borderStyle: "solid",
      px: 2,
      py: 1,
      boxShadow: "default",
      textDecoration: "none",
      transition: "all .3s ease-in-out",
      ":hover, :active, :focus": {
        bg: lighten("primary", 0.35),
        borderColor: darken("primary", 0.15),
        color: darken("primary", 0.15),
      },
    },
    cancel: {
      cursor: "pointer",
      color: "gray.7",
      bg: "gray.2",
      px: 2,
      py: 1,
      borderColor: "gray.2",
      borderWidth: "2px",
      borderRadius: "6px",
      borderStyle: "solid",
      boxShadow: "default",
      textDecoration: "none",
      transition: "all .3s ease-in-out",
      ":hover, :active, :focus": {
        bg: darken("gray.2", 0.15),
        borderColor: darken("gray.2", 0.15),
      },
    },
    icon: {
      cursor: "pointer",
      color: "text",
      bg: "transparent",
      p: 2,
      display: "grid",
      placeItems: "center",
      border: "none",
      borderRadius: "9999px",
      textDecoration: "none",
      transition: "all .3s ease-in-out",
      ":hover, :active, :focus": {
        bg: "gray.2",
      },
    },
    // Used in map-filter-menu.js
    filterMenuToggle: {
      cursor: "pointer",
      fontSize: 1,
      color: "text",
      bg: "background",
      height: "50px",
      py: 0,
      px: [null, null, 3, 4, null],
      borderStyle: "none",
      borderRightStyle: "solid",
      borderRightWidth: "1px",
      borderRightColor: "muted",
      boxShadow: "none",
      borderRadius: "0",
      transition: "all .3s ease-in-out",
      display: "flex",
      textDecoration: "none",
      alignItems: "center",
      ":hover, :active, :focus": {
        bg: "muted",
      },
    },
    // Used in form submissions
    submit: {
      cursor: "pointer",
      color: "white",
      bg: "primary",
      px: 3,
      py: 1,
      borderColor: "primary",
      borderWidth: "2px",
      borderRadius: "9999em",
      borderStyle: "solid",
      boxShadow: "default",
      textDecoration: "none",
      transition: "all .3s ease-in-out",
      ":hover, :active, :focus": {
        bg: darken("primary", 0.1),
        borderColor: darken("primary", 0.1),
      },
      ":after": {
        content: '"\\00A0 \\2192"',
      },
    },
    // Used on homescreen for CTAs
    homeCta: {
      cursor: "pointer",
      color: "white",
      bg: "accent",
      px: 3,
      py: 1,
      borderColor: "accent",
      borderWidth: "2px",
      borderStyle: "solid",
      borderRadius: "9999em",
      boxShadow: "none",
      transition: "all .3s ease-in-out",
      textDecoration: "none",
      fontSize: [1, 2, null, null, null],
      ":hover, :active, :focus": {
        bg: darken("accent", 0.15),
        borderColor: darken("accent", 0.15),
      },
    },
  },
  variants: {},
}

export default theme
