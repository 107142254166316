import React, { useState, createContext } from "react"

export const HasQueryContext = createContext()

export const HasQueryProvider = ({ children }) => {
  const [hasQuery, setHasQuery] = useState(false)

  return (
    <HasQueryContext.Provider value={[hasQuery, setHasQuery]}>
      {children}
    </HasQueryContext.Provider>
  )
}
