import React, { useState, createContext } from "react"

export const HasPopularFiltersContext = createContext()

export const HasPopularFiltersProvider = ({ children }) => {
  const [hasPopularFilters, setHasPopularFilters] = useState(false)

  return (
    <HasPopularFiltersContext.Provider
      value={[hasPopularFilters, setHasPopularFilters]}
    >
      {children}
    </HasPopularFiltersContext.Provider>
  )
}
